import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../../components";
import locales from "../../../constants";

const Hean = ({ pageContext: { slug } }) => {
  const lang = "en";
  const nav = {
    initialSlideIndex: 1,
  };
  return (
    <Layout
      header={{
        background:
          "linear-gradient( 45deg, #6B165A, #A6228B, #B6249A, #A6228B, #6B165A )",
        icons: "#c929aa",
        navClass: "hean",
      }}
      seo={{
        title: "Hean",
        headerTitle: "hean",
        href: slug,
        lang: "en",
        ogImage: require("../../../assets/img/portfolio/hean_top_back.png"),
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/projekty/hean/",
      }}
    >
      <PortfolioHeader name="hean" height="86" />
      <section className="container-fluid hean_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Hean</h1>
              <ul>
                <li>Packaging design</li>
                <li>Catalogues</li>
                <li>Product visualizations</li>
                <li>Product photos</li>
              </ul>
            </div>
          </div>

          <div className="col-md-6">
            <div className="inner">
              <p>
                In cooperation with Hean, we have designed the cosmetic product
                catalogues. Our main task was to present products in the most
                attractive and sales-promoting way - this was particularly
                important due to the high competitiveness of the products. We
                have made packaging designs for all foundations, eye shadows,
                lipsticks, and labels (Matte Touch Fluid Series, Art Makeup
                Fluid Series, Make-Up Remover Series, Cream Boutique Series,
                Hean Basic Care Cream Series.). We were also responsible for
                making product visualizations and product photos. In addition,
                we designed many sales aids, such as leaflets, makeup stands, or
                retail stands.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="hean_section_3">
        <img src={require("../../../assets/img/portfolio/hean.png")} alt="" />
      </section>
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default Hean;
